import React from 'react'

import { Grid, Typography } from '@mui/material'
import SectionLayout from '../SharedComponents/SectionLayout'

const InvestHeroSection = () => (
  <SectionLayout name="invest-hero-section" backgroundColor="trustBlue.main">
    <Grid container item sm={12}>
      <Typography variant="h1" color="moneyGreen.main">
        Flock empowers landlords to exit their rentals with the 721 exchange.
      </Typography>
    </Grid>
  </SectionLayout>
)

export default InvestHeroSection
