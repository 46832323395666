import React from 'react'
import InvestMissionSectionDesktop from './InvestMissionSectionDesktop'
import InvestMissionSectionTablet from './InvestMissionSectionTablet'
import InvestMissionSectionMobile from './InvestMissionSectionMobile'
import useInvestMissionSection from './useInvestMissionSection'

const InvestMissionSection = () => {
  const presentationalProps = useInvestMissionSection()
  const { isMobile, isTablet } = presentationalProps

  if (isMobile) {
    return <InvestMissionSectionMobile {...presentationalProps} />
  }
  if (isTablet) {
    return <InvestMissionSectionTablet {...presentationalProps} />
  }
  return <InvestMissionSectionDesktop {...presentationalProps} />
}

export default InvestMissionSection
