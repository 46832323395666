import React from 'react'
import PageWrapper from '../components/SharedComponents/PageWrapper'
import InvestHeroSection from '../components/InvestWithFlockComponents/InvestHeroSection'
import InvestCtaSection from '../components/InvestWithFlockComponents/InvestCtaSection'
import { useRecordPageDuration } from '../components/utils'
import InvestMissionSection from '../components/InvestWithFlockComponents/InvestMissionSection/InvestMissionSection'

const pageTitle = 'Flock | Invest with Flock'
const pageDescription = `We’re on a mission to deliver financial freedom to owners, better living experiences for residents, and durable value to the community.`

export const Head = () => (
  <>
    <title>{pageTitle}</title>
    <meta title="title" property="og:title" content={pageTitle} />
    <meta
      name="description"
      property="og:description"
      content={pageDescription}
    />
  </>
)

const InvestWithFlock = () => {
  useRecordPageDuration()

  return (
    <PageWrapper
      title={pageTitle}
      trackingName="invest-with-flock"
      headerBackground="trustBlue.main"
    >
      <InvestHeroSection />
      <InvestMissionSection />
      <InvestCtaSection />
    </PageWrapper>
  )
}

export default InvestWithFlock
